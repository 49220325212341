import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import Breadcrumbs from "examples/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const fetchUserData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/user-notifications`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const results = await response.json();

      setUserData(results.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Upload failed. Please try again.";
      console.log(errorMessage);
    }
  }, [token]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, [fetchUserData, token, navigate]);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleRemoveItem = async (notification_id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}users/remove-user-notifications/${notification_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await response.json();
        fetchUserData();
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Upload failed. Please try again.";
        console.log(errorMessage);
      }
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{
        mt: 2,
      }}
    >
      {userData && userData.length > 0
        ? userData.map((list, index) => (
            <MenuItem
              key={index}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <MDBox
                component={Link}
                py={0.5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                lineHeight={1}
                width="100%"
              >
                <MDBox display="flex" alignItems="center">
                  <MDTypography
                    variant="body1"
                    color="secondary"
                    lineHeight={0.75}
                  >
                    <ArrowOutwardIcon />
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{
                      ml: 1,
                      whiteSpace: "break-spaces",
                      fontSize: {
                        xs: "0.875rem", // Smaller font size for smaller screens
                        sm: "1rem", // Regular font size for larger screens
                      },
                    }}
                  >
                    {list.message}
                  </MDTypography>
                </MDBox>
                <MDTypography
                  variant="body1"
                  color="dark"
                  lineHeight={0.75}
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemoveItem(list._id);
                  }}
                  sx={{
                    cursor: "pointer",
                    fontSize: {
                      xs: "1.25rem", // Adjust size of CancelIcon based on screen size
                      sm: "1.5rem",
                    },
                  }}
                >
                  <CancelIcon />
                </MDTypography>
              </MDBox>
            </MenuItem>
          ))
        : "There was no notification found."}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* Main container with flexbox */}
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb={{ xs: 1, md: 0 }}
        >
          {/* Left side: Breadcrumbs */}
          <MDBox>
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </MDBox>

          {/* Right side: Menu and Notifications Icons */}
          {isMini ? null : (
            <MDBox display="flex" alignItems="center">
              {/* Menu Icon */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              {/* Notifications Icon */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Stack spacing={2} direction="row">
                  <Badge
                    badgeContent={userData && userData.length}
                    color="error"
                  >
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </Badge>
                </Stack>
              </IconButton>
              {renderMenu()}
            </MDBox>
          )}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
