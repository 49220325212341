import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import Webcam from "react-webcam";
import { Toaster } from "react-hot-toast";
import ErrorDialog from "components/Dialog/error";
import ProgressBar from "components/ProgressBar";
import CameratDarkImage from "assets/images/icons/Camera-1.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SuccessDialog from "components/Dialog/success";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function FaceRecognition() {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const token = localStorage.getItem("token");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isStartDialogOpen, setIsStartDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImageSrc(imageSrc);
      } else {
        console.error("Screenshot failed, imageSrc is null");
      }
    } else {
      console.error("webcamRef.current is null");
    }
  }, [webcamRef]);

  const openImageCapture = () => {
    setIsConfirmed(true);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };
  const handleFileUpload = async () => {
    setIsConfirmed(true);
    const base64Response = await fetch(imageSrc);
    const blob = await base64Response.blob();

    const formData = new FormData();
    formData.append("file", blob, "captured-image.jpg");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/upload-facephoto`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // If the response is successful
      if (response.ok) {
        // toast.success("Documents uploaded successfully");

        await response.json();
        setSuccessMessage("Document submitted Successfully");
        setIsSuccessDialogOpen(true);
        // setSuccessMessage("Document submitted Successfully");
        // setIsSuccessDialogOpen(true);
        // navigate("/facerecognition");
      } else {
        const errorData = await response.json();
        console.log(errorData);
      }
    } catch (error) {
      const errorMessage = error.message || "Upload failed. Please try again.";
      console.log(errorMessage);
      // setErrorMessage(errorMessage);
      // setIsErrorDialogOpen(true);
    }
  };

  const StartDialog = ({ open, handleClose }) => (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Submission Requirements</DialogTitle>
      <DialogContent>
        <MDBox>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <StarIcon sx={{ marginRight: 2 }} color="info" />{" "}
                <ListItemText secondary="Please hold your live photo with half body." />
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton>
                <StarIcon color="info" sx={{ marginRight: 2 }} />
                <ListItemText secondary="Please hold your passport with full body." />
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton>
                <StarIcon color="info" sx={{ marginRight: 2 }} />
                <ListItemText secondary="Please make sure that background should be white and clean." />
              </ListItemButton>
            </ListItem>
          </List>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ width: "100%" }}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <MDButton
            variant="contained"
            size="large"
            onClick={() => {
              handleClose();
              openImageCapture();
            }}
            sx={{
              background:
                "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
              color: "#fff",
              "&:hover": {
                backgroundColor: "darkgreen",
              },
            }}
          >
            Ok
          </MDButton>
        </MDBox>
      </DialogActions>
    </Dialog>
  );

  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}users/get-user-profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const results = await response.json();

        setUserData(results.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Upload failed. Please try again.";
        console.log(errorMessage);
      }
    };

    if (!token) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, [token, navigate]);
  // console.log(userData);
  const checkDocumentStatus = () => {
    if (
      userData &&
      userData.isDocument !== true &&
      userData.stage !== "Completed"
    ) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You have to submit the passport document first before face recognition."
      );
      setTitleDialogMessage("Document Pending for Upload");
      return true;
    }

    if (
      userData &&
      userData.stage === "Completed" &&
      userData.status === "Under_review"
    ) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "We appreciate your patience as we review your documents; an  administrator will be in contact with you soon."
      );
      setTitleDialogMessage("Under Review.");
      return true;
    }

    if (
      userData &&
      userData.stage === "Completed" &&
      userData.status === "Resubmission"
    ) {
      return false;
    }

    if (userData && userData.status === "Rejected") {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You are no longer able to upload documents since the admin rejected you."
      );
      setTitleDialogMessage("Rejected");
      return true;
    }
    return false;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <ProgressBar activeStep={2} />
        {!isConfirmed && (
          <Grid container spacing={6} mt={1}>
            <Grid item xs={12}>
              {!isMobile ? (
                <MDBox display="flex" justifyContent="center" mb={2}>
                  <img alt="document" src={CameratDarkImage} />
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="center">
                  <img alt="document" src={CameratDarkImage} width="35%" />
                </MDBox>
              )}
              <MDBox
                textAlign="center"
                sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
              >
                <MDBox>
                  <MDTypography variant="h1" sx={{ fontSize: "2rem" }}>
                    Face Verification
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular"
                  >
                    System will capture live face for verification.
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <MDButton
                    variant="contained"
                    size="large"
                    onClick={() => {
                      if (!checkDocumentStatus()) {
                        setIsStartDialogOpen(true);
                      }
                    }}
                    sx={{
                      background:
                        "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                  >
                    Start
                  </MDButton>
                  <StartDialog
                    open={isStartDialogOpen}
                    handleClose={() => setIsStartDialogOpen(false)}
                  />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        )}
        {isConfirmed && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MDBox pt={3} mx={2} px={2}>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6} sm={6} lg={6} p={2}>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width="100%"
                      videoConstraints={{ facingMode: "user" }}
                      style={{ borderRadius: "14px" }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} p={2}>
                    {imageSrc && (
                      <div>
                        <img
                          src={imageSrc}
                          alt="Captured"
                          style={{ width: "100%", borderRadius: "14px" }}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6} sm={6} lg={6} p={2}>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <MDButton
                        type="button"
                        variant="contained"
                        onClick={capture}
                        sx={{
                          background:
                            "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "darkgreen",
                          },
                        }}
                      >
                        {imageSrc ? "Capture Again" : "Capture"}
                      </MDButton>
                    </MDBox>
                  </Grid>
                  {imageSrc && (
                    <Grid item xs={6} sm={6} lg={6} p={2}>
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <MDButton
                          type="button"
                          variant="contained"
                          color="info"
                          onClick={handleFileUpload}
                          sx={{
                            background:
                              "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "darkgreen",
                            },
                          }}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </Grid>
                  )}
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
      <ErrorDialog
        open={isErrorDialogOpen}
        handleClose={handleDialogClose}
        title={titleDialogMessage}
        message={errorMessage}
      />
      <SuccessDialog
        open={isSuccessDialogOpen}
        handleClose={handleDialogSuccessClose}
        title="Document Submitted"
        message={successMessage}
        redirectUrl="/upload-photo"
      />
    </DashboardLayout>
  );
}

export default FaceRecognition;
