import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMediaQuery } from "react-responsive";
import bgImage from "assets/images/bg.png";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import icon from "assets/images/icon.png";
import metamask from "assets/images/Metamask.png";
import logo from "assets/images/logo.png";
function Basic() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const onClickHandle = async () => {
    if (typeof window.ethereum !== "undefined") {
      toast.success("MetaMask is connected!");
    } else {
      toast.error("MetaMask is not connected");
    }
    setLoading(true);
    try {
      const [account] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const checksumAddress = await ethers.getAddress(account);
      // setWalletAddress(checksumAddress);
      // console.log(checksumAddress);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/check-address`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            walletAddress: checksumAddress,
          }),
        }
      );

      const result = await response.json();
      console.log(result);
      if (result.code === 1) {
        const message = `Sign this message to login. Wallet address: ${checksumAddress}`;
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [
            ethers.hexlify(ethers.toUtf8Bytes(message)),
            checksumAddress,
          ],
        });

        const responseSign = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}users/verify-signature`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              walletAddress: checksumAddress,
              signature: signature,
              message: message,
            }),
          }
        );

        const resultSign = await responseSign.json();
        console.log(resultSign);
        if (resultSign.code === 1) {
          localStorage.setItem("token", resultSign.data.token);
          navigate("/home");
        } else {
          setError(resultSign.error.message);
        }
      } else {
        setError(result.error.message);
      }
    } catch (err) {
      console.log(err);
      setError("An error occurred while connecting to MetaMask");
    } finally {
      setLoading(false);
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Toaster />

      <Card
        sx={{
          backgroundColor: "#151515",
          boxShadow: 3,
          mx: 1,
          mt: -3,
          p: 5,
          mb: 1,
          textAlign: "center",
          border: "1px solid green",
        }}
      >
        {loading && <div className="loader"></div>}
        <MDBox>
          {!isMobile ? (
            <MDBox component="img" src={icon} alt="Logo" width="4rem" />
          ) : (
            <MDBox component="img" src={logo} alt="Logo" width="10rem" />
          )}

          <MDTypography
            variant="h2"
            fontWeight="medium"
            color="white"
            mt={2}
            mb={3}
          >
            Connect To ICB KYC
          </MDTypography>

          <MDTypography
            variant="h6"
            fontWeight="medium"
            mt={1}
            mb={4}
            sx={{ color: "red" }}
          >
            {error || ""}
          </MDTypography>
          <MDBox
            component="form"
            role="form"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <MDButton
              variant="gradient"
              onClick={onClickHandle}
              disabled={loading}
              sx={{
                backgroundColor: "#323232",
                color: "#fff",
              }}
            >
              <MDBox
                component="img"
                src={metamask}
                alt="Logo"
                width={40}
                mr={1}
              />{" "}
              Continue with MetaMask
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
