import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DocumentDarkImage from "assets/images/icons/photo.png";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import ProgressBar from "components/ProgressBar";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
function UploadPhoto() {
  const token = localStorage.getItem("token");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const triggerFileSelection = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/jpg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/webp")
    ) {
      setSelectedFile(file);
    } else {
      setErrorMessage(
        "For verification, kindly upload a passport-sized photo with the file extensions.jpeg,.jpg,.png, or.webp."
      );
      setTitleDialogMessage("Document Rejected");
      setIsErrorDialogOpen(true);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setErrorMessage("Please select a document before uploading.");
      setTitleDialogMessage("Document Rejected");
      setIsErrorDialogOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/upload-photo`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        await response.json();
        setSuccessMessage("Document submitted Successfully");
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Unknown error occurred");
        setTitleDialogMessage("Document Rejected");
        setIsErrorDialogOpen(true);
      }
    } catch (error) {
      const errorMessage = error.message || "Upload failed. Please try again.";
      setErrorMessage(errorMessage);
      setTitleDialogMessage("Document Rejected");
      setIsErrorDialogOpen(true);
    }
  };
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}users/get-user-profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const results = await response.json();

        setUserData(results.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Upload failed. Please try again.";
        console.log(errorMessage);
      }
    };

    if (!token) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, [token, navigate]);

  const checkDocumentStatus = () => {
    if (userData && userData.isDocument !== true) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You must submit the passport document before you may upload a photo."
      );
      setTitleDialogMessage("Document Upload Incomplete");
      return true;
    } else if (userData && userData.isFace !== true) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You can't upload a photo until you can identify the face."
      );
      setTitleDialogMessage("Face Verification Incomplete");
      return true;
    }
    if (
      userData &&
      userData.stage === "Completed" &&
      userData.status === "Under_review"
    ) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "We appreciate your patience as we review your documents; an  administrator will be in contact with you soon."
      );
      setTitleDialogMessage("Under Review.");
      return true;
    }

    if (userData && userData.status === "Rejected") {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You are no longer able to upload documents since the admin rejected you."
      );
      setTitleDialogMessage("Rejected");
      return true;
    }

    if (
      userData &&
      userData.stage === "Completed" &&
      userData.status === "Resubmission"
    ) {
      return false;
    }
    return false;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        <ProgressBar activeStep={3} />
        <Grid container spacing={6} mt={1}>
          <Grid item xs={12}>
            {!isMobile ? (
              <MDBox display="flex" justifyContent="center" mb={2}>
                <img alt="document" src={DocumentDarkImage} />
              </MDBox>
            ) : (
              <MDBox display="flex" justifyContent="center">
                <img alt="document" src={DocumentDarkImage} width="35%" />
              </MDBox>
            )}

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <MDBox
              textAlign="center"
              sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
            >
              <MDBox textAlign="center">
                <MDTypography variant="h1" sx={{ fontSize: "2rem" }}>
                  Upload Photo
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                >
                  Please upload passport size photo
                </MDTypography>
              </MDBox>

              {selectedFile ? (
                <MDBox>
                  <MDButton
                    variant="contained"
                    size="large"
                    onClick={handleFileUpload}
                    sx={{
                      background:
                        "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                  >
                    UPLOAD
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox>
                  <MDButton
                    variant="contained"
                    size="large"
                    sx={{
                      background:
                        "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                    onClick={() => {
                      if (!checkDocumentStatus()) {
                        triggerFileSelection();
                      }
                    }}
                  >
                    SELECT Photo
                  </MDButton>
                </MDBox>
              )}
              <ErrorDialog
                open={isErrorDialogOpen}
                handleClose={handleDialogClose}
                title={titleDialogMessage}
                message={errorMessage}
              />
              <SuccessDialog
                open={isSuccessDialogOpen}
                handleClose={handleDialogSuccessClose}
                title="Document Submitted"
                message={successMessage}
                redirectUrl="/payment"
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UploadPhoto;
