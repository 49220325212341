import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import toast, { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DocumentDarkImage from "assets/images/payment.png";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import { useNavigate } from "react-router-dom";
import ProgressBar from "components/ProgressBar";
import TextField from "@mui/material/TextField";
import "./custom.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CoinImage from "assets/images/icons/coin.png";
import { useMediaQuery } from "react-responsive";
function Payment() {
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);
  const [userPaymentData, setUserPaymentData] = useState(null);
  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formError, setFormError] = useState(false);
  const [hash, setHash] = useState("");
  console.log("Payment Request ....");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    const isValid = form.checkValidity();

    // Correct check for hash value
    if (!hash || hash.trim() === "" || hash.length < 64) {
      setErrorMessage(
        "Please enter a valid transaction hash (minimum 64 characters)."
      );
      setTitleDialogMessage("Invalid Transaction Hash");
      setIsErrorDialogOpen(true);
      return; // Prevent form submission
    }

    // Additional form validation check
    if (!isValid) {
      setFormError(true);
      return;
    }

    setFormError(false);
    handlePaymentIntegration();
  };

  const handlePaymentIntegration = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/payment`,
        {
          method: "POST",
          body: JSON.stringify({ hash: hash }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        await response.json();
        setSuccessMessage("Document submitted Successfully");
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Unknown error occurred");
        setTitleDialogMessage("Something's went wront");
        setIsErrorDialogOpen(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };
  const address = "TQu2n9e9MocYD1dbjDe4QBVkTP1gAqAsZi";

  const handleCopy = () => {
    navigator.clipboard.writeText(address).then(() => {
      toast.success("Address copied to clipboard!");
    });
  };
  const fetchUserCouponPaymentData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/coupon-payment-byadmin`,
        {
          method: "Post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await response.json();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Upload failed. Please try again.";
      console.log(errorMessage);
    }
  }, [token]);

  const isDocumentValid = useCallback(
    () => userData?.isDocument === true,
    [userData]
  );
  const isFaceValid = useCallback(() => userData?.isFace === true, [userData]);
  const isPhotoValid = useCallback(
    () => userData?.isPhoto === true,
    [userData]
  );
  const isCouponPaymentValid = useCallback(
    () => userPaymentData?.code !== 0,
    [userPaymentData]
  );

  const fetchUserData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/get-user-profile`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const results = await response.json();
      setUserData(results.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Upload failed. Please try again.";
      console.log(errorMessage);
    }
  }, [token]);
  const fetchUserCouponData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/checkCouponForUser`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const results = await response.json();
      setUserPaymentData(results.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Upload failed. Please try again.";
      console.log(errorMessage);
    }
  }, [token]);

  const checkDocumentStatus = () => {
    if (userData && userData.isDocument !== true) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You must submit the passport document before you may upload a photo."
      );
      setTitleDialogMessage("Document Upload Incomplete");
      return true;
    } else if (userData && userData.isFace !== true) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "Before making a payment, you must be identify the face."
      );
      setTitleDialogMessage("Face Verification Incomplete");
      return true;
    } else if (userData && userData.isPhoto !== true) {
      setIsErrorDialogOpen(true);
      setErrorMessage("Before making a payment, a photo must be uploaded.");
      setTitleDialogMessage("Photo Upload Incomplete");
      return true;
    }
    if (
      userData &&
      userData.stage === "Completed" &&
      userData.isPayment === true &&
      userData.status === "Under_review"
    ) {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "We appreciate your patience as we review your documents; an  administrator will be in contact with you soon."
      );
      setTitleDialogMessage("Under Review.");
      return true;
    }

    if (
      userData &&
      userData.stage === "Completed" &&
      userData.status === "Resubmission"
    ) {
      return false;
    }

    if (userData && userData.status === "Rejected") {
      setIsErrorDialogOpen(true);
      setErrorMessage(
        "You are no longer able to upload documents since the admin rejected you."
      );
      setTitleDialogMessage("Rejected");
      return true;
    }
    return false;
  };

  //   useEffect(() => {
  //     if (!token) {
  //       navigate("/");
  //     } else {
  //       fetchUserData();
  //       fetchUserCouponData();

  //       if (isDocumentValid() && isFaceValid() && isPhotoValid()) {
  //         if (isCouponPaymentValid()) {
  //           fetchUserCouponPaymentData();
  //         } else {
  //         }
  //       } else {
  //       }
  //     }
  //   }, [
  //     token,
  //     navigate,
  //     fetchUserData,
  //     fetchUserCouponData,
  //     fetchUserCouponPaymentData,
  //     isDocumentValid,
  //     isFaceValid,
  //     isPhotoValid,
  //     isCouponPaymentValid,
  //   ]);
  const [isCouponPaymentFetched, setIsCouponPaymentFetched] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/");
        return;
      }

      try {
        await fetchUserData();

        await fetchUserCouponData();

        if (
          isDocumentValid() &&
          isFaceValid() &&
          isPhotoValid() &&
          isCouponPaymentValid() &&
          !isCouponPaymentFetched
        ) {
          await fetchUserCouponPaymentData();
          setIsCouponPaymentFetched(true);
        }

        setIsDataFetched(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!isDataFetched) {
      fetchData();
    }
  }, [
    token,
    navigate,
    fetchUserData,
    fetchUserCouponData,
    fetchUserCouponPaymentData,
    isDocumentValid,
    isFaceValid,
    isPhotoValid,
    isCouponPaymentValid,
    isCouponPaymentFetched,
    isDataFetched,
  ]);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        <ProgressBar activeStep={4} />
        {userPaymentData &&
        userPaymentData.code !== 0 &&
        (userPaymentData !== null || userPaymentData !== undefined) ? (
          <>
            {" "}
            <Grid container spacing={6} mt={1}>
              <Grid item xs={12}>
                {!isMobile ? (
                  <MDBox textAlign="center" mb={2}>
                    <img
                      alt="document"
                      src={CoinImage}
                      style={{ cursor: "pointer", width: 130 }}
                    />
                  </MDBox>
                ) : (
                  <MDBox textAlign="center" mb={2}>
                    <img
                      alt="document"
                      src={CoinImage}
                      style={{ cursor: "pointer", width: "30%" }}
                    />
                  </MDBox>
                )}

                <MDBox
                  textAlign="center"
                  sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h3" style={{ color: "green" }}>
                      {" "}
                      Coupon Applied Successfully 😊, Enjoy the ICB KYC without
                      Payment
                    </MDTypography>{" "}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={6} mt={1}>
              <Grid item xs={12}>
                <MDBox textAlign="center" mb={2}>
                  {!isMobile ? (
                    <img
                      alt="document"
                      src={DocumentDarkImage}
                      style={{ cursor: "pointer", width: 130 }}
                    />
                  ) : (
                    <img
                      alt="document"
                      src={DocumentDarkImage}
                      style={{ cursor: "pointer", width: "35%" }}
                    />
                  )}

                  <MDBox
                    textAlign="center"
                    mb={2}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <MDTypography variant="h6" sx={{ mr: 1 }}>
                      {address}
                    </MDTypography>
                    <Tooltip title="Copy">
                      <IconButton onClick={handleCopy} aria-label="copy">
                        {" "}
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <MDBox
                  textAlign="center"
                  sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h1" sx={{ fontSize: "2rem" }}>
                      {" "}
                      Open any wallet and pay on this address
                    </MDTypography>{" "}
                    <MDTypography
                      variant="h3"
                      color="error"
                      className="animated-text"
                      sx={{
                        mt: 2,
                      }}
                    >
                      {" "}
                      Pay USDT on Tron Blockchain
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    textAlign="center"
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      justifyContent: "center",
                      "& .MuiTextField-root": {
                        m: 2,
                        width: { xs: "100%", sm: "50ch" },
                      },
                    }}
                  >
                    <TextField
                      required
                      id="outlined-required"
                      placeholder="Paste your transaction hash here"
                      label="Transaction Hash"
                      color="secondary"
                      focused
                      error={formError}
                      helperText={
                        formError && "Please enter the transaction hash code"
                      }
                      value={hash}
                      onChange={(e) => setHash(e.target.value)}
                    />
                    <MDButton
                      type="submit"
                      variant="contained"
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!checkDocumentStatus()) {
                          handleSubmit(e);
                        }
                      }}
                      sx={{
                        background:
                          "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                        },
                        m: 2,
                        mt: 1,
                      }}
                    >
                      Submit
                    </MDButton>
                  </MDBox>

                  <ErrorDialog
                    open={isErrorDialogOpen}
                    handleClose={handleDialogClose}
                    title={titleDialogMessage}
                    message={errorMessage}
                  />
                  <SuccessDialog
                    open={isSuccessDialogOpen}
                    handleClose={handleDialogSuccessClose}
                    title="Document Submitted"
                    message={successMessage}
                    redirectUrl="/view-status"
                  />
                </MDBox>
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Payment;
