// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DocumentImage from "assets/images/smallIcons/img1.png";
import FaceImage from "assets/images/smallIcons/img2.png";
import PhotoImage from "assets/images/smallIcons/img3.png";
import StatusImage from "assets/images/smallIcons/img5.png";
import PaymentImage from "assets/images/smallIcons/img4.png";
import MDButton from "components/MDButton";
import ProgressBar from "components/ProgressBar";
import DocumentDarkImage from "assets/images/icons/selected/Document.png";
import CameratDarkImage from "assets/images/icons/selected/Camera.png";
import PhotoDarkImage from "assets/images/icons/selected/Vector.png";
import PaymentDarkImage from "assets/images/icons/selected/beta.png";
import StatusDarkImage from "assets/images/icons/selected/Vector-1.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import HandshakeImage from "assets/images/handshake2.png";

function Dashboard() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}users/get-user-profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const results = await response.json();

        setUserData(results.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Upload failed. Please try again.";
        console.log(errorMessage);
      }
    };

    if (!token) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, [token, navigate]);
  useEffect(() => {
    if (userData && userData.status === "Completed") {
      setIsSuccessDialogOpen(true);
    }
  }, [userData]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <ProgressBar
          activeStep={userData && userData.status === "Completed" ? 5 : 0}
        />
        <MDBox>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={3}>
                <Card
                  sx={{
                    border: `1px solid ${
                      userData && userData.status === "Completed"
                        ? "#3A8A11"
                        : `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`
                    }`,
                    height: "100%", // Ensures card takes up full height
                    display: "grid",
                    gridTemplateRows: "auto 1fr auto",
                  }}
                >
                  <MDBox padding="1rem" mt={1}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox display="flex" justifyContent="center">
                        <img
                          alt="Icon"
                          src={
                            userData && userData.status === "Completed"
                              ? DocumentDarkImage
                              : DocumentImage
                          }
                        />
                      </MDBox>
                    </MDBox>

                    <MDBox
                      pt={3}
                      pb={1}
                      px={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        flexGrow: 1,
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          color: `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`,
                        }}
                      >
                        Document Verification
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={3}>
                <Card
                  sx={{
                    border: `1px solid ${
                      userData && userData.status === "Completed"
                        ? "#3A8A11"
                        : `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`
                    }`,
                    height: "100%", // Ensures card takes up full height
                    display: "grid",
                    gridTemplateRows: "auto 1fr auto",
                  }}
                >
                  <MDBox padding="1rem" mt={1}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox display="flex" justifyContent="center">
                        <img
                          alt="Icon"
                          src={
                            userData && userData.status === "Completed"
                              ? CameratDarkImage
                              : FaceImage
                          }
                        />
                      </MDBox>
                    </MDBox>

                    <MDBox
                      pt={3}
                      pb={1}
                      px={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        textTransform="capitalize"
                        sx={{
                          color: `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`,
                        }}
                      >
                        Face Verification
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={3}>
                <Card
                  sx={{
                    border: `1px solid ${
                      userData && userData.status === "Completed"
                        ? "#3A8A11"
                        : `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`
                    }`,
                    height: "100%", // Ensures card takes up full height
                    display: "grid",
                    gridTemplateRows: "auto 1fr auto",
                  }}
                >
                  <MDBox padding="1rem" mt={1}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox display="flex" justifyContent="center">
                        <img
                          alt="Icon"
                          src={
                            userData && userData.status === "Completed"
                              ? PhotoDarkImage
                              : PhotoImage
                          }
                        />
                      </MDBox>
                    </MDBox>

                    <MDBox
                      pt={3}
                      pb={1}
                      px={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        textTransform="capitalize"
                        sx={{
                          color: `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`,
                        }}
                      >
                        Upload Photo
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={3}>
                <Card
                  sx={{
                    border: `1px solid ${
                      userData && userData.status === "Completed"
                        ? "#3A8A11"
                        : `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`
                    }`,
                    height: "100%", // Ensures card takes up full height
                    display: "grid",
                    gridTemplateRows: "auto 1fr auto",
                  }}
                >
                  <MDBox padding="1rem" mt={1}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox display="flex" justifyContent="center">
                        <img
                          alt="Icon"
                          src={
                            userData && userData.status === "Completed"
                              ? PaymentDarkImage
                              : PaymentImage
                          }
                        />
                      </MDBox>
                    </MDBox>

                    <MDBox
                      pt={3}
                      pb={1}
                      px={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        textTransform="capitalize"
                        sx={{
                          color: `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`,
                        }}
                      >
                        Payment Process
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={3}>
                <Card
                  sx={{
                    border: `1px solid ${
                      userData && userData.status === "Completed"
                        ? "#3A8A11"
                        : `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`
                    }`,
                    height: "100%", // Ensures card takes up full height
                    display: "grid",
                    gridTemplateRows: "auto 1fr auto",
                  }}
                >
                  <MDBox padding="1rem" mt={1}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox display="flex" justifyContent="center">
                        <img
                          alt="Icon"
                          src={
                            userData && userData.status === "Completed"
                              ? StatusDarkImage
                              : StatusImage
                          }
                        />
                      </MDBox>
                    </MDBox>

                    <MDBox
                      pt={3}
                      pb={1}
                      px={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        textTransform="capitalize"
                        sx={{
                          color: `${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : "#BCBCBC"
                          }`,
                        }}
                      >
                        Check Status
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox textAlign="center" mt={10}>
            <MDBox>
              <Typography variant="h1">Welcome to ICB KYC</Typography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="text" fontWeight="regular">
                Please upload the related documents to continue
              </MDTypography>
            </MDBox>
            {/* Start Button */}
            <MDBox>
              <Button
                variant="contained"
                size="large"
                component={Link} // Link is added as the component
                to="/documents"
                sx={{
                  background:
                    "linear-gradient(94deg, #50811E 0.13%, #172A0D 100.13%)",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                Continue
              </Button>
            </MDBox>
          </MDBox>
          {/* <SuccessDialog
            open={isSuccessDialogOpen}
            handleClose={handleDialogSuccessClose}
            title="Congratulation! 🥳"
            message="Approved ! Creating NFT Soon 😊"
            redirectUrl="/profile"
          /> */}

          <MDBox display="flex" justifyContent="center" mb={4}>
            <Dialog
              open={isSuccessDialogOpen}
              onClose={handleDialogSuccessClose}
              aria-labelledby="error-dialog-title"
              aria-describedby="error-dialog-description"
              fullWidth={true} // Ensures the dialog stretches as per the content
            >
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={6}
              >
                <img alt="document" src={HandshakeImage} width={100} />

                <DialogTitle id="error-dialog-title" align="center">
                  Congratulation! 🥳
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="error-dialog-description"
                    align="center"
                  >
                    We are happy 😊 to announce that after reviewing your
                    documents. You have successfullly based the KYC. We will be
                    providing NFT Soon on the wallet address you have
                    resigtered.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton
                    onClick={handleDialogSuccessClose}
                    variant="contained"
                    size="large"
                    sx={{
                      background:
                        "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                  >
                    OK
                  </MDButton>
                </DialogActions>
              </MDBox>
            </Dialog>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
