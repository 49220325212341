import React from "react";
import MDBox from "components/MDBox";
import DocumentImage from "assets/images/icons/Document.png";
import FaceImage from "assets/images/icons/Camera.png";
import PhotoImage from "assets/images/icons/Vector.png";
import StatusImage from "assets/images/icons/Vector-1.png";
import PaymentImage from "assets/images/icons/beta.png";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import DocumentSelectedImage from "assets/images/icons/selected/Document.png";
import FaceSelectedImage from "assets/images/icons/selected/Camera.png";
import PhotoSelectedImage from "assets/images/icons/selected/Vector.png";
import StatusSelectedImage from "assets/images/icons/selected/Vector-1.png";
import PaymentSelectedImage from "assets/images/icons/selected/beta.png";
import Grid from "@mui/material/Grid";
function ProgressBar({ activeStep }) {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,

      left: "calc(-50% + 24px)",
      right: "calc(50% + 24px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,#3A8A11 0%,#3A8A11 50%,#3A8A11 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,#3A8A11 0%,#3A8A11 50%,#3A8A11 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      border: 0,
      backgroundColor: "#BCBCBC",

      borderRadius: 1,
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    border: "2px solid",
    borderColor: ownerState.completed ? "#40811E" : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[700],
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: completed ? (
        <img src={DocumentSelectedImage} alt="Document Icon" width={30} />
      ) : (
        <img src={DocumentImage} alt="Document" width={30} />
      ),
      2: completed ? (
        <img src={FaceSelectedImage} alt="Face Icon" width={30} />
      ) : (
        <img src={FaceImage} alt="Face" width={30} />
      ),
      3: completed ? (
        <img src={PhotoSelectedImage} alt="Upload Icon" width={30} />
      ) : (
        <img src={PhotoImage} alt="Upload" width={30} />
      ),
      4: completed ? (
        <img src={PaymentSelectedImage} alt="Upload Icon" width={30} />
      ) : (
        <img src={PaymentImage} alt="Upload" width={30} />
      ),
      5: completed ? (
        <img src={StatusSelectedImage} alt="Upload Icon" width={30} />
      ) : (
        <img src={StatusImage} alt="Upload" width={30} />
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const steps = [
    "Document Verification",
    "Face Verification",
    "Upload Photo",
    "Payment",
    "Check Status",
  ];

  return (
    <MDBox display="flex" justifyContent="center" mb={4}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox display="flex" justifyContent="center" mb={4}>
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
                sx={{
                  background: "transparent !important",
                  boxShadow: "none !important",
                }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {/* {label} */}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ProgressBar;
